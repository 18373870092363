@import '../../../../../shared/ui-kit-2/_constants/colors.scss';
@import "../../../../../app/global-styles/vars";

.Container {
  position: relative;
  display: inline-flex;
  width: 100%;
  margin: 432px auto 0;
  color: $white;

  @media screen and (min-width: $landing-screen-laptop) and (max-width: $landing-screen-laptop-l) {
    .Wrapper {
      width: 1058px;
    }

    .Card {
      &__Sticker {
        display: none;
      }

      &__Row {
        flex-direction: column;
      }

      &__Title {
        margin-bottom: 5px;
        font-size: 30px;
      }

      &__Subtitle {
        font-size: 14px;
      }

      &__Text {
        &_special {
          margin-left: 0;
          font-size: 14px;
        }

        &_perModem {
          margin-top: 8px;
        }

        &_bold {
          font-size: 30px;
        }

        &_italics {
          font-size: 18px;
        }

        &_info {
          flex-direction: row;
          align-items: flex-start;
          margin-top: 8px;
          grid-gap: 20px;
        }

        &_green {
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 100.2%
        }
      }
    }
  }

  @media screen and (max-width: $landing-screen-laptop) {
    width: 100vw;
    margin: calc(78rem / $rem-mobile) 0 calc(41rem / $rem-mobile);
    overflow: hidden;
    border-radius: calc(30rem / $rem-mobile);

    .Wrapper {
      width: 100%;
      margin: 0;
      padding: 0 0 calc(70rem / $rem-mobile) 0;
    }

    .Background {
      left: 0;
      width: 100%;
    }

    .Slider {
      padding: 0 calc(16rem / $rem-mobile);
    }

    .Row {
      flex-direction: column;
      margin-bottom: calc(42rem / $rem-mobile);
      padding: calc(50rem / $rem-mobile) calc(40rem / $rem-mobile) 0 calc(16rem / $rem-mobile);
    }

    .Title {
      margin-bottom: calc(24rem / $rem-mobile);
      font-size: calc(40rem / $rem-mobile);
      letter-spacing: 0;
    }

    .Subtitle {
      width: 100%;
      font-size: calc(14rem / $rem-mobile);

      p {
        margin-top: 0;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .Card {
      width: auto;
      height: auto;
      min-width: calc(291rem / $rem-mobile);
      min-height: calc(318rem / $rem-mobile);
      padding: calc(24rem / $rem-mobile) calc(16rem / $rem-mobile);

      &__Title {
        font-size: calc(30rem / $rem-mobile);
      }

      &__Subtitle {
        font-size: calc(14rem / $rem-mobile);
      }

      &__Row {
        &_title {
          margin-bottom: calc(5rem / $rem-mobile);
        }

        &_price {
          margin-bottom: calc(26rem / $rem-mobile);
          flex-direction: column;
          grid-gap: calc(12rem / $rem-mobile);
        }
      }

      &__Text {
        &_bold {
          font-size: calc(30rem / $rem-mobile);
        }

        &_italics {
          font-size: calc(18rem / $rem-mobile);
        }

        &_sub {
          margin-left: 0;
          font-size: calc(14rem / $rem-mobile);
        }

        &_info {
          align-items: flex-start;
        }

        &_green {
          font-size: calc(14rem / $rem-mobile);
        }
      }
    }

    .Background {
      width: 250%;
      padding: 0;
    }
  }
}

.Wrapper {
  position: relative;
  width: 1280px;
  margin: 0 auto;
  padding: 45px 0 65px;
  border-radius: 20px;
  z-index: 1;
}

.Row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 75px;
}

.Title {
  font-family: Gilroy, sans-serif;
  font-size: 109px;
  font-style: normal;
  font-weight: 700;
  line-height: 94.2%;
  letter-spacing: -3px;
}

.Subtitle {
  width: 555px;
  font-family: Rubik, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
}

.Grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 420px;
  grid-gap: 25px;
}

.Card {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 24px;
  color: $dark-text;
  background: $white;
  border-radius: 36px;
  background: $white-bg;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.07), 0 0 21px 0 rgba(0, 0, 0, 0.60), 1px 1px 2px 0 rgba(0, 0, 0, 0.20), -3px -3px 6px 0 rgba(255, 255, 255, 0.21), 1px 1px 1px 0 rgba(255, 255, 255, 0.24);
  backdrop-filter: blur(12px);
  font-family: Gilroy, sans-serif;

  &__Row {
    display: flex;
    justify-content: space-between;

    &_price {
      margin-bottom: 32px;
    }

    &_title {
      margin-bottom: 8px;
    }
  }

  &__Title {
    color: $dark-text;
    font-size: 35px;
    font-style: normal;
    font-weight: 700;
  }

  &__Subtitle {
    color: $dark-text;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
  }

  &__Text {
    margin-top: auto;
    color: $dark-text;
    line-height: 1;

    &_info {
      display: flex;
      flex-direction: column;
      align-items: end;
      grid-gap: 8px;
      color: $dark-text;
    }

    &_bold {
      color: $dark-text;
      font-size: 47px;
      font-weight: 600;
      line-height: 1;
    }

    &_italics {
      display: inline-block;
      color: $dark-text;
      font-size: 1.6875rem;
      font-weight: 400;
    }

    &_sub {
      margin-left: 4px;
      color: $dark-text;
      font-size: 18px;
      font-weight: 400;
      line-height: 1;
    }

    &_green {
      color: $green;
      font-family: Gilroy, sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
    }
  }

  &__Top {
    padding-left: 0.5rem;
  }
}

.Background {
  position: absolute;
  top: 0;
  left: 16px;
  width: calc(100% - 32px);
  height: 100%;
  padding: 0;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
  }
}

.Slider {
  width: 100%;
}