
.Content {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  z-index: 1;
}

.Top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.StickerRow {
  display: flex;
  align-items: center;
}

.Title {
  margin-right: 16px;
  color: #32435B;
  font-family: Gilroy, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.Cols {
  display: flex;
  align-items: center;
  grid-gap: 8px;
}

.Counter {
  color: #32435B;
  font-family: Gilroy, sans-serif;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 1;
}

.CounterSubText {
  width: 45px;
  color: #32435B;
  font-family: Gilroy, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}

.Navigate {
  margin-top: auto;
}

.Background {
  position: absolute;
  bottom: -12px;
  right: -8px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  background: rgba(189, 229, 197, 0.50);
  color: #39BE46;
  border-radius: 100px;

  & > {
    width: 60px;
    height: 60px;
  }
}
