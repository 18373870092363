$white: #FFFFFF;
$white-bg: #F6F6FB;
$page-wrapper: #ECECF6;
$bg: #F3F4F6;
$bg-gray: #EAEAEA;
$bg-gray-2: #EBEBF5;
$bg-gray-3: #EDEDF6;
$bg-gray-4: #E0E0EB;
$sidebar-link-active: #EEEEF7;
$segmented-control-gray: #E8E8EF;
$badge-gray: #E4E8ED;
$button-gray: #E8E8EF;
$border-gray: #BFCCD7;
$text-gray: #7B8AA0;
$dark-text: #32435B;
$light-green: #BDE5C5;
$green: #39BE46;
$green-dark: #39BE46;
$red-alert: #DC5050;
$light-yellow: #EBE88D;
$yellow: #E3E87E;
$light-blue: #BED5ED;
$blue: #B8D8F6;
$purple: #DBB8FF;
$danger: #FF8A00;

