
.NotFoundDevicesBanner {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ModemImage {
  margin-bottom: 12px;
  height: 343px;
}

.Title {
  margin-bottom: 24px;
  color: #32435B;
  font-family: Gilroy, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 1;
}

.Subtitle {
  margin-bottom: 24px;
  color: #32435B;
  text-align: center;
  font-family: Rubik, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
}

.Button {
  width: 196px;
  height: 48px;
}