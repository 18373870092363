@import "../../app/global-styles/vars";
@import "../../shared/ui-kit-2/_constants/colors";

.Wrapper {
  padding: 114px 0 24px 0;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  //min-height: calc(100vh - #{$footer-height});
  //min-height: calc(var(--app-height) - #{$footer-height});
  min-height: var(--app-height);
  background: $page-wrapper;

  &_authenticated {
    padding: 114px 0 24px 210px;

    .Content {
      max-width: $container-xl;
    }
  }

  &_loading {
    justify-content: center;

    .Content {
      margin-top: -112px;
    }
  }

  &_noPadding {
    padding: 0;
  }

  @media screen and (max-width: $lk-mobile-l) {
    position: relative;

    .Content {
      padding: 0;
    }

    &_authenticated {
      padding: 76px 16px 78px 16px;
    }
  }
}

.Content {
  width: 100%;
  padding: 0 24px;
  max-width: $screen-xl;

  @media screen and (min-width: $lk-tablet) {
    padding: 0 32px;
  }
}

.Loading {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 24px;
}