
.IconButton {
  display: flex;
  justify-content: center;
  align-content: center;
  width: 16px;
  height: 16px;
  padding: 0;
  background: none;
  color: #7B8AA0;
  border: none;
  outline: none;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    opacity: 0.7;
  }
}