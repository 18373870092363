
.CardAdditional {
  position: relative;
  width: 100%;
  min-height: calc(100vh - 120px);
  margin-bottom: -114px;
  padding: 0 48px;
  overflow: hidden;
}

.Content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-gap: 48px;
  margin: auto;
}

.Icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  background: #BDE5C5;
  color: #39BE46;
  border-radius: 100px;

  img {
    width: 60px;
    height: 60px;
  }

  &_error {
    background: #FFBEBE;
    color: #DC5050;
  }
}

.Title {
  color: #32435B;
  text-align: center;
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Gilroy, sans-serif;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 54px;
}

.ErrorText {
  margin-top: -24px;
  color: #32435B;
  text-align: center;
  font-family: Gilroy, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5;
}

.Help {
  display: grid;
  grid-template-columns: 48px 1fr;
  grid-gap: 12px;
  max-width: 716px;
  padding: 12px;
  border-radius: 28px;
  border: 1px solid var(--border-gray, #BFCCD7);

  &__Icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    background: #FFE7CB;
    color: #FF8A00;
    border-radius: 16px;

    img {
      width: 24px;
      height: 24px;
    }
  }

  &__Text {
    color: #32435B;
    font-variant-numeric: lining-nums proportional-nums;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Gilroy, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 1.5;
  }
}

.Subscription {
  display: flex;
  align-items: center;
  grid-gap: 24px;
  height: 55px;
  padding: 0 16px;
  border-radius: 20px;
  border: 1px solid #39BE46;
  background: #EEEEF7;

  &__Title {
    width: 150px;
    color: #32435B;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Gilroy, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 1;
  }

  &__Item {
    display: flex;
    align-items: center;
    grid-gap: 8px;
  }

  &__Name {
    color: #32435B;
    font-variant-numeric: lining-nums tabular-nums;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Rubik, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
  }

  &__Value {
    color: #32435B;
    text-align: right;
    font-variant-numeric: lining-nums tabular-nums;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Gilroy, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 1;
  }
}

.Actions {
  display: flex;
  grid-gap: 16px;
  margin: 0 auto;
}

.Button {
  width: 260px;
}