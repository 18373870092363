@import '../../../../../shared/ui-kit-2/_constants/colors.scss';
@import '../../../../../shared/ui-kit-2/_constants/typography.scss';
@import "../../../../../app/global-styles/vars";

.Card {
  display: flex;
  flex-direction: column;
  width: 313px;
  height: 100%;
  padding: 24px 16px;
  border-radius: 20px;
  border: 1px solid $border-gray;
  background: $white-bg;

  &_modemInfo {
    padding: 18px;
    grid-area: modemInfo;
    position: relative;
    overflow: hidden;
  }
  @media (max-width: $lk-mobile-l) {
    &_modemInfo {
      height: auto;
      padding: 20px 16px;
    }
  }
}

.Top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;

  &__Title {
    display: flex;
    align-items: center;
    grid-gap: 8px;
  }
}

.Title {
  max-width: 180px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: Gilroy, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  z-index: 3;
}

.Item {
  display: flex;
  align-items: center;
  grid-gap: 8px;
  height: 22px;
  margin-top: 5px;
  z-index: 3;
}

.Label {
  color: $text-gray;
  font-family: Rubik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.2;
}

.Action {
  display: flex;
  align-items: center;
  padding: 0;
  color: #7B8AA0;
  font-family: Gilroy, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  border: none;
  background: transparent;
  transition: 0.3s;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }

  svg {
    width: 16px;
    height: 16px;
  }
}

.Bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  z-index: 3;
}

.Status {
  display: flex;
  flex-direction: row;
  column-gap: 13px;
  align-items: center;
  color: $red-alert;
  @include font-rubik-16;
}

.Row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 4px;
}

.RebootButton {
  display: flex;
  flex-direction: row;
  column-gap: 4px;
  align-items: center;
  @include font-gilroy-12;
  color: $dark-text;
  border: none;
  padding: 10px 7px;
  border-radius: 13px;
  background: $white;
  cursor: pointer;

  svg {
    width: 14px;
    height: 14px;
  }
}

.Control {
  &:not(:last-child) {
    svg {
      path {
        stroke: $dark-text;
        fill: none;
      }
    }
  }

  button {
    min-width: 34px;
    min-height: 34px;
    max-width: 34px;
    max-height: 34px;
    width: 34px;
    height: 34px;
    background: $white;
    border-radius: 13px;
    transition: 0.3s;

    svg {
      path {
        fill: $dark-text;
      }
    }

    &:hover {
      opacity: 0.7;
    }
  }
}

.VideoContainer {
  position: absolute;
  width: 234px;
  height: 153px;
  overflow: hidden;
  top: 0;
  right: 0;
  border-radius: 20px;
  z-index: 0;
}

.Video {
  position: absolute;
  width: 234px;
  top: -107px;
  height: 302%;
  z-index: 0;
}

.Mask {
  z-index: 2;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 234px;
  border-radius: 20px;
}