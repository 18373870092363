@import "../../_constants/typography";
@import "../../_constants/colors";
@import "../../_constants/transition-mixins";

.Container {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 5px;
  max-width: max-content;
  width: 100%;
}

.Switcher {
  cursor: pointer;
  min-width: 36px;
  width: 36px;
  height: 19px;
  background: $bg-gray;
  border-radius: 36px;
  position: relative;
  @include transition-default(all);

  &_white {
    background: $white;
  }
}

.Circle {
  position: absolute;
  top: 1px;
  left: 1px;
  background: $green;
  width: 17px;
  height: 17px;
  border-radius: 100%;
  @include transition-default(all);
  filter: drop-shadow(0px 3px 1px rgba(0, 0, 0, 0.03)) drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.01)) drop-shadow(0px 3px 8px rgba(0, 0, 0, 0.10));
}

.HiddenInput {
  display: none;

  &:checked ~ .Switcher {
    background: $green;

    .Circle {
      left: 18px;
      background: $white;
    }
  }

  &:disabled ~ .Switcher {
    background: $bg-gray;
    cursor: default;

    .Circle {
      background: $light-green;
    }
  }

  &:checked:disabled ~ .Switcher {
    background: $light-green;

    .Circle {
      background: $white;
    }
  }
}

.LabelWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 2px;
  user-select: none;
  word-break: break-word;
}

.Label {
  @include font-rubik-14;
  color: $dark-text;

  & > span {
    color: $red-alert;
  }
}

.Error {
  @include font-rubik-12;
  color: $red-alert;
}