
.Card {
  position: relative;
  width: 100%;
  min-height: calc(100vh - 150px);
  margin-bottom: -114px;
  padding: 66px;
  background: #F6F6FB;
  border-radius: 30px;
  overflow: hidden;
}

.Content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-gap: 60px;
}

.Icons {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 48px;
}

.Icon {
  width: 160px;
  height: 160px;
}

.Text {
  max-width: 660px;
  color: #32435B;
  text-align: center;
  white-space: pre-line;
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Gilroy, sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.25;
}

.Actions {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 16px;
}

.Button {
  width: 260px !important;
}