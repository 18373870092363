@import "../../../../shared/ui-kit-2/_constants/typography";
@import "../../../../shared/ui-kit-2/_constants/colors";
@import "../../../../app/global-styles/vars";

.Cards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(262px, 1fr));
  grid-gap: 16px;
  width: 100%;
  margin-top: 24px;
}

.PageHeader {
  display: flex;
  align-items: center;
  flex-direction: row;;
  width: 100%;
  justify-content: space-between;
}

.TitleAndCounter {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: center;
  column-gap: 17px;
}

.Title {
  @include font-gilroy-27;
  color: $dark-text;
}

.Counter {
  padding: 4px 8px;
  @include font-rubik-12;
  color: $green-dark;
  border: 1px solid $green-dark;
  border-radius: 43px;

  &_empty {
    border: 1px solid $border-gray;
    color: $text-gray;
  }
}

.SearchInputContainer {
  width: 315px;

  @media (max-width: $lk-mobile-l) {
    width: auto;
    margin-left: auto;
  }
}

.NotFound {
  margin-top: 48px;
}