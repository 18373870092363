@import '../../../../../shared/ui-kit-2/_constants/colors.scss';
@import '../../../../../shared/ui-kit-2/_constants/typography.scss';

.List {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.Label {
  color: $text-gray;
  font-family: Rubik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.2;
}

.Col {
  display: flex;
  flex-direction: column;
  grid-gap: 16px;
  width: 100%;

  &:first-child {
    padding-right: 16px;
    border-right: 1px solid #BFCCD7;
  }

  &:last-child {
    padding-left: 16px;
  }
}

.Row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 4px;
}

.Value {
  color: $dark-text;
  font-family: Gilroy, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

.ErrorSubscription {
  opacity: 0.3;
  pointer-events: none;
}