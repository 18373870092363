
.Content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 32px;

  @media screen and (max-width: 1200px) {
    grid-template-columns: 100%;
  }
}

.Card {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 30px;
  min-height: calc(100vh - 150px);
  margin-bottom: -114px;
  padding: 30px;
  background: #F6F6FB;
  border-radius: 30px;

  @media screen and (max-width: 1200px) {
    margin-bottom: 0;
  }
}

.Title {
  color: #32435B;
  text-align: center;
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Gilroy, sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.25;
}

.Button {
  width: 260px !important;
  margin-top: auto;
}

.CenteredItem {
  margin: auto;
}

.Block {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__Circle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70px;
    height: 70px;
    margin-bottom: 16px;
    border-radius: 100px;
    background: #BDE5C5;
    color: #39BE46;
  }

  &__Title {
    margin-bottom: 8px;
    color: #32435B;
    text-align: center;
    font-variant-numeric: lining-nums proportional-nums;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Gilroy, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px;
  }

  &__Text {
    color: #32435B;
    text-align: center;
    font-variant-numeric: lining-nums proportional-nums;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Gilroy, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
  }
}

.Or {
  margin: 32px 0;
  color: #32435B;
  text-align: center;
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Gilroy, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}