@use "./global-styles/normalize";
@use "./global-styles/vars";
@use "./global-styles/fonts";
@import "./global-styles/vars";
@import './../shared/ui-kit-2/_constants/colors.scss';

:global {
  [class*="container"][class*="visible"] {
    opacity: 0!important;
    visibility: hidden !important;
  }

  @media (max-width: $lk-mobile-l) {
    [class*="wrap"][class*="orientationRight"][class*="jivoMobileButton"] {
      display: none!important;
    }
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  overflow-x: hidden;
  background: $page-wrapper;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.AppWrapper {
}

.AppLoader {
  min-height: var(--app-height);
  width: 100%;
}