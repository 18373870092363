@import '../../../../../../../../shared/ui-kit-2/_constants/typography.scss';
@import '../../../../../../../../shared/ui-kit-2/_constants/colors.scss';

.CardAdditional {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 30px 20px 32px 32px;
}

.Header {
  margin-bottom: 37px;
  @include font-gilroy-48;
  color: $dark-text;
}

.ProductName {
  color: $green;
  display: inline-block;
}
