@import "../../_constants/typography";
@import "../../_constants/colors";

.ActionCard {
  display: flex;
  flex-direction: column;
  padding: 24px 16px 20px 16px;
  background: $white-bg;
  border-radius: 20px;

  &_alert {
    justify-content: space-between;
  }
}

.Top {
  display: flex;
  justify-content: space-between;
  grid-gap: 8px;
  margin-bottom: 16px;

  &__Info {
    width: calc(100% - 4px - 24px);
  }

  &__Name {
    width: 100%;
    margin-bottom: 4px;
    color: $dark-text;
    font-family: Gilroy, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__Number {
    min-height: 16px;
    color: $text-gray;
    font-family: Gilroy, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
  }

  &__Action {
    width: 24px;
    height: 24px;
    padding: 0;
    background: transparent;
    border: none;
    transition: 0.3s;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }
}

.Switcher {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: 8px;
  width: 100%;
  height: 48px;
  margin-bottom: 16px;
  padding: 4px;
  background: $bg-gray-2;
  border-radius: 20px;

  &__LeftMode, &__RightMode {
    width: 48%;
    height: 100%;
    color: #000;
    background: transparent;
    border: none;
    border-radius: 15px;
    font-family: Rubik, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    transition: 0.3s;
    cursor: pointer;

    &:hover {
      opacity: 0.4;
    }

    &_normal {
      &.Switcher__LeftMode_active, &.Switcher__RightMode_active {
        background: #fff !important;
        color: #000 !important;
      }
    }
  }

  &__LeftMode {
    &_active {
      background: $green;
      color: $white;

      &:hover {
        opacity: 1;
        cursor: default;
      }
    }
  }

  &__RightMode {
    &_active {
      background: $red-alert;
      color: $white;

      &:hover {
        opacity: 1;
        cursor: default;
      }
    }
  }

  &__Loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
  }
}

.CopyBlock {
  display: flex;
  flex-direction: column;
  grid-gap: 16px;

  &__Group {
    display: flex;
    flex-direction: column;
  }

  &__Row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 23px;
  }

  &__Right {
    display: flex;
    align-items: center;
    grid-gap: 4px;
    max-width: 60%;
    min-width: 5%;
  }

  &__Name {
    color: $text-gray;
    font-family: Gilroy, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.2;
  }

  &__Value {
    color: $dark-text;
    font-family: Gilroy, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    text-overflow: ellipsis;
    white-space: normal;
    overflow: hidden;
  }

  &__Button {
    margin: 4px 0 0 2px;
    padding: 0;
    background: transparent;
    border: none;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
      opacity: 0.7;
    }
  }
}

.Alert {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-gap: 8px;
  margin: auto 0;
  padding: 16px 0;

  &__Text {
    max-width: 70%;
    text-align: center;
    color: #FF8A00;
    font-family: Rubik, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
  }
}