@import '../../../../../../app/global-styles/vars.scss';
@import '../../../../../../shared/ui-kit-2/_constants/colors.scss';
@import '../../../../../../shared/ui-kit-2/_constants/typography.scss';
@import '../../../../../../shared/ui-kit-2/_constants/transition-mixins';

.PaymentMethodCard {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 277px;
  padding: 24px;
  background: $white-bg;
  box-shadow: 0 6px 24.2px 0 rgba(0, 0, 0, 0.07);
  border-radius: 20px;
  cursor: pointer;
  @include transition-default(box-shadow);

  &:hover {
    outline: 1px solid $green;
  }

  &_active {
    outline: 1px solid #26A632;
    box-shadow: none;
  }

  &_disabled {
    cursor: default;
    opacity: 0.4;
  }

  &:not(&_active) &_disabled {
    &:hover {
      outline: none;
    }
  }
}

.TitleAndRadio {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 10px;
  width: 100%;
  justify-content: space-between;
  padding-bottom: 24px;
  border-bottom: 1px solid #BFCCD7;
}

.Title {
  display: flex;
  align-items: center;
  grid-gap: 16px;

  &__Text {
    @include font-gilroy-20;
    font-weight: 700;
    line-height: 1.2;
    color: #32435B;
  }
}

.SubscriptionInfo {
  display: flex;
  flex-direction: row;
  column-gap: 58px;
}

.SubscriptionWrapper {
  display: flex;
  flex-direction: column;
  row-gap: 7px;
}

.SubscriptionTitle {
  @include font-gilroy-14;
  color: $text-gray;
}

.SubscriptionValue {
  @include font-gilroy-16;
  font-weight: 600;
  color: $dark-text;
}

.SubscriptionDescription {
  @include font-gilroy-14;
  color: $dark-text;
  line-height: 20px;
}

.ErrorSubscription {
  color: $red-alert;
}

.TariffMonth {
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  align-items: center;
  justify-self: flex-end;
  @include font-gilroy-27;
  color: $dark-text;
  margin-top: auto;
}

.TariffAnnualCurrent {
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  align-items: center;
  @include font-gilroy-27;
  color: $dark-text;
  margin-top: auto;
}

.TariffAnnual {
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  align-items: center;
  @include font-gilroy-27;
  color: $dark-text;
  margin-top: auto;

  &_disabled {
    opacity: 0.3;
  }
}

.AnnualTariffWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

.AnnualDescription {
  width: 236px;
  @include font-gilroy-14;
  line-height: 20px;
  color: $dark-text;
}

.Gifts {
  color: $green-dark;
  @include font-rubik-16;
}

.Checkbox {
  width: 16px;
  height: 16px;
  border-radius: 100%;
  background: $bg-gray;
  position: relative;
  min-width: 16px;
}

.Active {
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10px;
    height: 10px;
    background: $green;
    border-radius: 100%;
    transform: translate(-50%, -50%);
  }
}

.ChildrenPaymentCard {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding-top: 24px;
}