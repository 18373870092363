@import '../../../../../shared/ui-kit-2/_constants/colors.scss';
@import '../../../../../shared/ui-kit-2/_constants/typography.scss';

.Subscription {
  display: flex;
  align-items: center;
  grid-gap: 24px;
  padding: 16px;
  background: #EEEEF7;
  border-radius: 20px;

  &_isActive {
    border: 1px solid #39BE46;
  }

  &_noSubscription {
    justify-content: space-between;
  }
}

.MainCol {
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
}

.Cols {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.Col {
  &__Name {
    color: #7B8AA0;
    font-family: Gilroy, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }

  &__Value {
    color: #32435B;
    font-family: Gilroy, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 1.5;
  }
}

.Row {
  display: flex;
  align-items: center;
  grid-gap: 8px;
}

.Label {
  color: #202D40;
  font-family: Gilroy, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.Col {
  display: flex;
  flex-direction: column;
  grid-gap: 4px;
  padding: 0 24px;
  border-right: 1px solid #BFCCD7;

  &:last-child {
    padding-right: 0;
    border-right: none;
  }
}

.Title {
  color: #32435B;
  font-family: Gilroy, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.EditButton {
  color: #39BE46;

  svg {
    width: 24px;
    height: 24px;
  }
}

.NoSubscriptionText {
  color: #32435B;
  font-family: Rubik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.2
}