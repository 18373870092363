
.RowCell {
  display: flex;
  align-items: center;
  grid-gap: 8px;
  padding: 8px 0;
  overflow-x: auto;

  & > div, & > span {
    flex-shrink: 0;
  }
}