@import "../../../../../../shared/ui-kit-2/_constants/typography";
@import "../../../../../../shared/ui-kit-2/_constants/colors";
@import '../../../../../../app/global-styles/vars';

.CallsSettingsPage {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(262px, 1fr));
  grid-gap: 16px;
}

.MuteMode {
  display: flex;
  justify-content: space-between;
  grid-gap: 8px;

  &__Col {
    display: flex;
    align-items: center;
  }

  &__Title {
    color: $dark-text;
    font-family: Gilroy, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
  }

  &__Subtitle {
    color: #7B8AA0;
    font-family: Gilroy, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
  }
}