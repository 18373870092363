
.Card {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 30px;
  width: 100%;
  height: calc(100vh - 60px);
  margin: 30px 0;
  padding: 30px;
  background: #F6F6FB;
  border-radius: 30px;
}

.Back {
  display: flex;
  align-items: center;
  grid-gap: 8px;
  align-self: flex-start;
  font-family: Gilroy, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  color: #39BE46;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    opacity: 0.7;
  }

  &__Icon {
    display: flex;
    justify-content: center;
    align-items: center;
    transform: rotate(180deg);
  }
}

.Subscriptions {
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-gap: 72px;
  height: 100%;
  margin-top: 48px;

  &__Title {
    color: #32435B;
    text-align: center;
    font-variant-numeric: lining-nums proportional-nums;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Gilroy, sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
  }
}

.Available {
  display: flex;
  align-items: center;
  grid-gap: 24px;
  margin: 0 auto;

  &__Item {
    display: flex;
    align-items: center;
    color: #32435B;
    white-space: pre;
    font-variant-numeric: lining-nums proportional-nums;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Gilroy, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px;
  }

  &__Text {}

  &__Count {
    color: #39BE46;
    white-space: pre;
  }
}

.Content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-gap: 48px;
}

.Button {
  width: 240px;
  margin: 0 auto;
}