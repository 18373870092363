
.Button {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 4px;
  width: max-content;
  height: 24px;
  padding: 0px 8px;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.3s;

  &_md {
    height: 32px;
    padding: 0px 16px;
    border-radius: 12px;

    .Text {
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
    }

    .Prefix svg, .Postfix svg {
      height: 20px;
    }
  }

  &_xl {
    height: 60px;
    padding: 0px 16px;
    border-radius: 20px;

    .Text {
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
    }
  }

  &_greenOutlined {
    background: transparent;
    border: 1px solid #39BE46;
    color: #39BE46;
  }

  &_greenFilled {
    background: #39BE46;
    border: 1px solid #39BE46;
    color: #FFF;

    &:not([disabled]):hover {
      background: #39BE46;
    }
  }

  &_white {
    background: transparent;
    border: 1px solid #FFF;
    color: #FFF;
  }

  &_greyOutlined {
    border: 1px solid #BFCCD7;
    color: #32435B;
    background: transparent;
  }

  &_disabled {
    opacity: 0.4;
    cursor: default;
  }
}

.Prefix svg, .Postfix svg {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 16px;
}

.Text {
  text-align: center;
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Gilroy, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 1;
}