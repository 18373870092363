@import '../../_constants/typography.scss';
@import '../../_constants/colors.scss';

.InputWrapper {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  position: relative;
  width: 100%;

  &_canBeShort {
    .ButtonDropdown {
    }

    .Dropdown {
    }

    .ValidationElement {
    }
  }
}

.LabelContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.Label {
  height: 20px;
  @include font-rubik-14;
  line-height: 20px;
  color: $dark-text;

  & > span {
    color: $red-alert;
  }
}

.Input {
  background: transparent;
  border: 1px solid $border-gray;
  padding: 21px 21px 21px 60px;
  border-radius: 20px;
  width: 100%;
  color: $dark-text;
  @include font-rubik-14;

  &::placeholder {
    @include font-rubik-14;
    color: $text-gray;
  }

  &:focus {
    outline: none;
    border: 1px solid $green;
  }

  &:hover {
    outline: none;
    border: 1px solid $green;
  }

  &_error {
    border: 1px solid $red-alert;
  }

  &_shortMode {
    padding-left: 21px;
  }
}

.ButtonDropdown {
  position: absolute;
  background: $bg-gray-2;
  top: 34px;
  left: 5px;
  font-size: 27px;
  border: none;
  padding: 0;
  width: 44px;
  height: 44px;
  border-radius: 17px;
  cursor: pointer;

  svg {
    position: absolute;
    top: 8px;
    left: 8px;
    width: 28px;
    height: 28px;
  }
}

.ButtonDropdownWithoutLabel {
  top: 6px;
}

.GlobeIcon {
  padding: 8px;
}

.Dropdown {
  display: flex;
  flex-direction: column;
  row-gap: 14px;
  position: absolute;
  padding: 14px 12px;
  background: $white;
  border-radius: 20px;
  height: 266px;
  width: 100%;
  top: 21px;
  z-index: 12;
}

.CountryOptionWrapper {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  height: 188px;
  overflow-y: scroll;
  width: 100%;
}

.CountryOption {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: transparent;
  padding: 4px 6.6px;
  border-radius: 10px;
  @include font-rubik-14;
  border: none;
  cursor: pointer;

  &:hover {
    background: $bg;
  }

  div:first-child {
    display: flex;
    flex-direction: row;
    column-gap: 5px;
    align-items: center;
    text-align: left;
    word-break: break-word;
  }
}

.SearchInputWrapper {
  position: relative;
}

.SearchInput {
  height: 48px;

  div {
    height: 48px;
  }
}

.SearchIcon {
  position: absolute;
  top: 36%;
  right: 20px;

  svg {
    width: 14px;
    height: 14px;
  }
}

.Error {
  margin-top: 4px;
  @include font-rubik-14;
  color: $red-alert;
  word-break: break-word;
}

.HelperText {
  margin-top: 8px;
  @include font-rubik-12;
  color: $text-gray;
  word-break: break-word;
}

.ValidationElement {
  position: absolute;
  right: 20px;
  top: 43px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ValidationElementWithoutLabel {
  top: 20px;
}

.InputContainer {
  position: relative;

  input {
    height: 55px;
  }
}

.Postfix {
  position: absolute;
  top: 34%;
  right: 10px;
  width: max-content;

  &_error {
    right: 50px;
  }
}