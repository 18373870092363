@import "../../_constants/typography";
@import "../../_constants/colors";
@import "../../_constants/transition-mixins";
@import '../../../../app/global-styles/vars.scss';

.CloudTabsWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto;

  @media (max-width: $lk-mobile-l) {
    border-radius: 0 0 26px 26px;
  }
}

.CloudTabs {
  display: flex;
  flex-direction: row;
  height: 42px;
  width: max-content;
}

.CloudTab {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 42px;
  box-sizing: border-box;
  padding: 11px 16px;
  width: max-content;
  white-space: nowrap;
  max-width: 100%;
  flex-grow: 1;
  @include font-gilroy(20px, 106%, 600);
  color: $text-gray;
  cursor: pointer;
  @include transition-default(all);
  border-radius: 32px;
  border: 1px solid transparent;
  background: transparent;
  transition: 0.3s;

  &:not(:disabled):hover {
    color: $dark-text;
  }

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }

  &_selected {
    color: $dark-text;
    border: 1px solid $green-dark;
  }

  @media (max-width: $lk-mobile-l) {
    @include font-gilroy-16;
  }
}

.EventsCount {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  margin-left: 8px;
  border-radius: 12px;
  background: #DC5050;
  color: #FFF;
  text-align: center;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Gilroy, sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 1.2;
}