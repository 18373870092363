
.ActivateModem {
  position: relative;
  grid-column: span 2;
  padding: 24px;
  border-radius: 20px;
  background: #39BE46;
  overflow: hidden;
}

.Background {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 317px;

  img {
    width: 100%;
    height: 100%;
  }
}

.Content {
  position: relative;
  display: flex;
  flex-direction: column;
  grid-gap: 16px;
  z-index: 1;
}

.Title {
  color: #FFF;
  font-family: Gilroy, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 1;
}

.Steps {
  display: flex;
  align-items: center;
  grid-gap: 8px;

  &__Count {
    color: #FFF;
    font-family: Gilroy, sans-serif;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 1;
  }

  &__Text {
    width: 110px;
    color: #FFF;
    font-family: Gilroy, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
  }
}