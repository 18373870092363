@import "../../../../../shared/ui-kit-2/_constants/colors";
@import "../../../../../shared/ui-kit-2/_constants/typography";
@import "../../../../../app/global-styles/vars";

.DetailsCard {
  position: relative;
  width: 218px;
  height: 100%;
  padding: 24px;
  background: #F6F6FB;
  border-radius: 20px;
  border: 1px solid #BFCCD7;
  overflow: hidden;
}

.Mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #F6F6FB;
  opacity: 0.85;
  pointer-events: none;
  z-index: 1;
}

.Top {
  display: flex;
  align-items: center;
}

.Bottom {
  display: flex;
  flex-direction: column;
  grid-gap: 16px;
}

.Divider {
  width: 100%;
  height: 1px;
  margin: 16px 0;
  background: #BFCCD7;
}

.Title {
  margin-right: 8px;
  color: #32435B;
  font-family: Gilroy, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 1;
}

.Settings {
  width: 24px;
  height: 24px;
  transition: 0.3s;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
}

.Switcher {
  margin-left: auto;
}

.Rows {
  display: flex;
  flex-direction: column;
  grid-gap: 16px;
}

.Item {
  display: flex;
  flex-direction: column;
  grid-gap: 4px;

  &__Label {
    color: #7B8AA0;
    font-family: Gilroy, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 1.2;
  }

  &__Value {
    display: flex;
    align-items: center;
    grid-gap: 8px;

    &__Text {
      color: #32435B;
      font-family: Gilroy, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 1.2;
    }
  }
}

.OfflineWifi {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  row-gap: 8px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  > div {
    color: $danger;
    width: 191px;
    @include font-rubik-14;
  }
}

.Action {
  display: flex;
  align-items: center;
  padding: 0;
  color: #7B8AA0;
  font-family: Gilroy, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  border: none;
  background: transparent;
  transition: 0.3s;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
}

.Warning {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-gap: 16px;
  width: 100%;
  height: 100%;
  z-index: 2;

  &__Text {
    color: #FF8A00;
    text-align: center;
    font-family: Rubik, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.2;
  }
}