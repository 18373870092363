@import "../../_constants/typography";
@import "../../_constants/colors";
@import "../../_constants/transition-mixins";

@keyframes dropdown-slide {
  from {
    top: 55px;
    opacity: 0
  }
  to {
    top: 63px;
    opacity: 1
  }
}

@keyframes dropdown-slide-small {
  from {
    top: 47px;
    opacity: 0
  }
  to {
    top: 55px;
    opacity: 1
  }
}

.Select {
    display: flex;
  flex-direction: column;
  row-gap: 8px;
}

.LabelContainer {
  display: flex;
  align-items: center;
  flex-direction: row;;
  justify-content: space-between;
}

.Label {
  @include font-rubik-14;
  color: $dark-text;

  & > span {
    color: $red-alert;
  }
}

.Area {
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 56px;
  box-sizing: border-box;
  border-radius: 20px;
  border: 1px solid $border-gray;
  background: $white-bg;

  &:hover {
    border: 1px solid $green;
  }

  &_error {
    border: 1px solid $red-alert;
  }

  &_disabled {
    // Disabled styles
  }

  &_small {
    height: 48px;
  }
}

.Placeholder {
  @include font-rubik-16;
  color: $text-gray;
  margin-left: 12px;
}

.Error {
  margin-top: 4px;
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  align-items: flex-start;

  & > svg {
    width: 16px;
    min-width: 16px;
    height: 16px;
    min-height: 16px;
  }

  & > span {
    color: $red-alert;
    @include font-rubik-14;
    word-break: break-word;
  }
}

.HelperText {
  color: $dark-text;
  @include font-rubik-12;
  margin-top: 5px;
  word-break: break-word;
}

.BadgesContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}

.Badges {
  width: calc(100% - 101px);
  margin-left: 8px;
  overflow-x: auto;
  display: flex;
  align-items: center;
  flex-direction: row;
  column-gap: 4px;
  -ms-overflow-style: none; /* для IE и Edge */
  scrollbar-width: none; /* для Firefox */
  z-index: 0;
  padding-right: 35px;

  &::-webkit-scrollbar {
    display: none;
  }
}

.Badge {
  width: max-content;
  align-items: center;
  background: $white;
  padding: 0 16px;
  color: $dark-text;
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  border-radius: 15px;
  height: 40px;
  @include font-rubik-14;
  white-space: nowrap;
  z-index: 10000;

  &_small {
    height: 32px;
  }
}

.InputPostfix {
  display: flex;
  flex-direction: row;
  column-gap: 16px;
  justify-content: space-between;
  align-items: center;
  padding-right: 16px;
  position: relative;

  &__overlay {
    pointer-events: none;
    background: linear-gradient(270deg, #F6F6FB 17.25%, rgba(246, 246, 251, 0.94) 73.93%, rgba(246, 246, 251, 0.00) 100.45%);
    width: 118px;
    position: absolute;
    height: 53px;
    right: 0;
    border-radius: 0 20px 20px 0;

    &_small {
      height: 44px;
    }
  }
}

.Arrow {
  width: 14px;
  height: 14px;
  transition: transform 0.2s ease;
  z-index: 999;
  cursor: pointer;

  &_active {
    transform: rotateZ(180deg);
  }
}

.Dropdown {
  cursor: default;
  position: absolute;
  top: 63px;
  z-index: 10;
  max-height: 320px;
  width: 100%;
  background: $white;
  border-radius: 20px;
  box-sizing: border-box;
  box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.13);
  padding: 8px 20px;
  animation: dropdown-slide 0.2s ease;

  &_small {
    top: 55px;
    animation: dropdown-slide-small 0.2s ease;
  }
}

.SearchContainer {
  display: flex;
flex-direction: row;
column-gap:  8px;
  width: 100%;
  margin: 9px 0 8px 0;
}

.Search {
  flex-grow: 1;

  &__icon {
    margin-right: 6px;
  }
}

.Options {
  overflow: auto;
  max-height: 250px;

  &::-webkit-scrollbar {
    width: 2px;
    height: 23px;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: $border-gray;
    border-radius: 10px;
  }
}

.Option {
  display: flex;
  flex-direction: row;
  column-gap: 16px;
  align-items: center;
  justify-items: center;
  padding: 10px 8px;
  box-sizing: border-box;
  @include font-rubik-16;
  border-radius: 10px;
  cursor: pointer;
  justify-content: space-between;

  &__text {
    color: $dark-text;
    flex-grow: 1;
    word-break: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    @include font-rubik-16;
    justify-content: space-between;

    span {
      // margin-right: 16px;
      color: $text-gray;
    }
  }

  &_disabled {
    opacity: 0.3;
    cursor: default;
  }

  &:hover:not(&_disabled) {
    background: #e3e3e3;
  }
}

.NotFound {
  @include font-rubik-14;
  color: $text-gray;
  padding: 6px 14px;
}
