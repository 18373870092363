
.Sticker {
  display: flex;
  justify-content: center;
  align-items: center;
  width: max-content;
  padding: 4px 8px;
  border-radius: 43px;
  font-family: Gilroy, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;

  &_red {
    background: #EDD1D1;
    color: #DC5050;
  }

  &_green {
    background: #BDE5C5;
    color: #39BE46;
  }

  &_grey {
    background: #E4E8ED;
    color: #32435B;
  }

  &_darkgrey {
    background: #C9CAD7;
    color: #202D40;
  }

  &_blue {
    background: #B8D8F6;
    color: #1C4A74;
  }

  &_textVariantDarkGreen {
    color: #2A5B33;
  }
}